* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.about,
.contact,
.career,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('./images/img-1.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
}
.career {
  background-image: url('./images/img-12.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
}
.contact {
  background-image: url('./images/img-11.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
}

.about {
  background-image: url('./images/img-10.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('./images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contact-us{
  padding: 20px;
  border-radius: 3%;
  background-color: #4570ce;
}
.contact-us input{
  background-color: #FAF3F0;
  border: none;
  border-radius: 10px;
  height: 2rem;
  padding-left: 15px;
}
.contact-us textarea{
  background-color: #FAF3F0;
  border: none;
  border-radius: 3%;
  padding-left: 15px;

}
.career-btn{
  background-color: transparent;
  padding: 0 8px 0 8px;
  border-width: 2px;
  border-radius: 30px;
  border-color: lightskyblue;
}
.career-img{
  width: 60%;
}
@media (max-width: 600px) {
  .career-img{
    width: 100%;
  }
  .txt-p{
    padding: 10px;
  }
  .crd-body-1{
    height: 30rem !important;
  }
}

/* Our Clients */
.image-gallery {
  display: flex; 
  align-items: center; 

  padding: 10px;
  overflow-x: auto;  
  justify-content: space-around;
  gap: 1rem;
}

.image-gallery img {
  max-width: 100%;
  height: auto; 
  margin-right: 5px; 
}

